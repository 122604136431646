@font-face {
  font-family: 'labMedium';
  src: url('../fonts/LabGrotesque-Medium.woff2') format('woff2'),
       url('../fonts/LabGrotesque-Medium.woff') format('woff'),
       url('../fonts/LabGrotesque-Medium.otf');
}

@font-face {
  font-family: 'labRegular';
  src: url('../fonts/LabGrotesque-Regular.woff2') format('woff2'),
       url('../fonts/LabGrotesque-Regular.woff') format('woff'),
       url('../fonts/LabGrotesque-Regular.otf') format('otf');
}

p {
  font-weight: 300;
}

a {
  color: $blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.link--styled {
    font-size: 0.8rem;
    color: $body-color;
    text-decoration: underline;
    display: block;
  }
  &.link--clean {
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
  }
}

.meta-sku {
  color: $skull-gray;
  font-size: 0.8rem;
}

.product__info__description {
  h1, .cm-header-1 {
    font-size: 24px !important;
    font-weight: bold !important;
    margin: 36px 0 0 0 !important;
  }
  h2, .cm-header-2 {
    font-size: 20px !important;
    font-weight: bold !important;
    margin: 30px 0 0 0 !important;
  }
  h3, .cm-header-3 {
    font-size: 16px !important;
    font-weight: bold !important;
    margin: 24px 0 0 0 !important;
  }
  p, .cm-paragraph {
    font-size: 16px !important;
    margin: 14px 0 !important;
  }
  ul, ol {
    font-size: 16px !important;
    margin: 14px 0 !important;
    padding-left: 20px !important;
  }
  blockquote, .cm-quote {
    font-size: 16px !important;
    margin: 14px 0 !important;
    padding-left: 20px !important;
    border-left: 3px solid #ccc !important;
    color: #555 !important;
  }
  hr, .cm-hr {
    border: none !important;
    border-top: 1px solid #ccc !important;
    margin: 30px 0 !important;
  }
}